import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './ServiceIntro.css';

const ServiceIntro = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // animation duration
            easing: 'ease-in-out', // animation easing
            once: true, // animation should only happen once
        });
    }, []);

    return (
        <div className="service-intro-container">
            <h1 data-aos="fade-up" data-aos-delay="100">Welcome to Our Services</h1>
            <p data-aos="fade-up" data-aos-delay="200">Explore our comprehensive range of services tailored to meet your digital needs. From content creation and social media promotions to performance marketing and website development, we are committed to delivering high-quality solutions that drive results and exceed expectations. Additionally, discover our AI-driven solutions and case studies showcasing our successful projects.</p>
        </div>
    );
}

export default ServiceIntro;
