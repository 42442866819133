import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import logo from '../../assets/logou-removebg-preview.png'; 

const Footer = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "+919763978086"; 
    const message = "Hello, I'd like to connect.";
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };
  return (
    <footer className="footer">
      <div className='footer-section'>
        <div className="footer-top">
          <div className="company-info">
            <img src={logo} alt="Company Logo" className="footer-logo" />
            <h2 className="footer-company-name ">Swayambhu Strategies</h2>
          </div>
        </div>

        

        <div className="footer-middle">
          <div className="find-us">
            <h3>Find Us</h3>
            <p>D4-102, Nyati Equatorial Phase 2 <br />
            Bavdhan, Pune - 411021</p>
          </div>
          <div className="connect">
        <h3>Connect Us</h3>
        <p id='email'>swayambhu@swayambhustrategy.com
</p>
        <p id='email'>Priyanka Daithankar (+91-9763978086)</p>
      </div>
      <div className="whatsapp">
        <h3>Connect Us via WhatsApp</h3>
        <div className="whatsapp-connect" >
          <input 
            type="text" 
            placeholder="click the arrow button" 
            className="whatsapp-input" 
            disabled
            
          />
          <button className="whatsapp-send" onClick={handleWhatsAppClick}>
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <ul className="footer-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about-us">About Us</Link></li>
            <li><Link to="/portfolio">Portfolio</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/careers">Careers</Link></li>
            <li><Link to="/contact-us">Contact Us</Link></li>
          </ul>
          <div className="footer-social-media">
            <FontAwesomeIcon icon={faInstagram} className="social-icon" />
            <FontAwesomeIcon icon={faFacebook} className="social-icon" />
            <FontAwesomeIcon icon={faTwitter} className="social-icon" />
            <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;


