import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Home9Comp.css';

const Home9Comp = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out', 
      once: false, 
    });
  }, []);

  return (
    <div className='home9-section'>
      <div className="home9-container">
        <div className="home9-left">
          <div className="home9-left-content" data-aos="fade-up" data-aos-delay="100">
            <h3>We Work With</h3>
            <h2>Visionary Founders & Companies To</h2>
            <h2>Work On Their Big Dream</h2>
          </div>
        </div>
        <div className="home9-right">
          <div className="home9-right-content" data-aos="fade-up" data-aos-delay="200">
            <p>At Swayambhu Strategies, we excel in creating engaging and high-quality content for various platforms. Whether it's OTT content, audio productions, TV shows, or films, our team ensures each project is executed with precision and creativity. Our social media content is designed to captivate audiences and drive engagement.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home9Comp;
