import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './ContentGallery.css';

const contentData = [
  {
    id: 1,
    title: 'Half Pants Full Pants (Hindi Web-series)',
    videoUrl: 'https://www.youtube.com/embed/ZjBOUVzuSuI',
  },
  {
    id: 2,
    title: 'Maharashtra Shahir (Marathi Movie)',
    videoUrl: 'https://www.youtube.com/embed/zIm-sVpPJrI',
  },
  {
    id: 3,
    title: 'Baipan Bhari Deva (Marathi Movie)',
    videoUrl: 'https://www.youtube.com/embed/KBpH0xpYdvc',
  },
  {
    id: 4,
    title: 'Pendulum (Malayalam Film)',
    videoUrl: 'https://www.youtube.com/embed/D6WpqraoNSE',
  },
  {
    id: 5,
    title: 'Main Atal Hoon (Hindi Film)',
    videoUrl: 'https://www.youtube.com/embed/zasckclx0Gc',
  },
  {
    id: 6,
    title: 'Vasaladi (Falguni Pathak’s Single)',
    videoUrl: 'https://www.youtube.com/embed/DcS3Xdjgl-U',
  },
  {
    id: 7,
    title: 'Vijeta (Marathi Movie)',
    videoUrl: 'https://www.youtube.com/embed/uJuVpZ2jUwA',
  },
  {
    id: 8,
    title: 'Single (Hindi)',
    videoUrl: 'https://www.youtube.com/embed/Lhmam_ZRA48',
  },
  {
    id: 9,
    title: 'Amhi Doghe Raja Rani (Marathi Daily Soap)',
    videoUrl: 'https://www.youtube.com/embed/lTLFc0r6m58',
  },
  {
    id: 10,
    title: 'Shatada Prem Karave (Marathi Daily Soap)',
    videoUrl: 'https://www.youtube.com/embed/f-5RUi6u2z0',
  },
  {
    id: 11,
    title: 'Agnihotra (Marathi Daily Soap)',
    videoUrl: 'https://www.youtube.com/embed/ScoFSoPtdU4',
  },
  {
    id: 12,
    title: 'Vedantu (TV Commercial)',
    videoUrl: 'https://www.youtube.com/embed/zY6EYhiU2W8',
  },
];

const ContentGallery = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: false,
    });
  }, []);

  return (
    <div className="content-gallery-container">
      <h2 data-aos="fade-up" data-aos-delay="100">A Glimpse of Our Work</h2>
      <div className="content-gallery-list">
        {contentData.map((project, index) => (
          <div key={index} className="content-gallery-item" data-aos="fade-up" data-aos-delay={200 + index * 100}>
            <iframe
              width="560"
              height="315"
              src={project.videoUrl}
              title={project.title}
              allowFullScreen
            ></iframe>
            {/* <hr className="content-gallery-divider" /> */}
            {/* <div className="content-read-more-container">
              <span className="content-read-more-text">Read more</span>
              <FontAwesomeIcon icon={faArrowRight} className="content-read-more-icon" />
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentGallery;
