import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import ContentCreationPage from '../Components/PortFolio/PortFolio1Comp';
import SocialMediaPromotionsPage from '../Components/Services/Services3Comp';
import PerformanceMarketingPage from '../Components/PortFolio/PortFolio1Comp';
import WebsiteDevelopmentPage from '../Components/PortFolio/PortFolio1Comp';
import AboutIntro from '../Components/AboutUs/AboutIntro ';
import Header from '../Components/Header/Header';
import PocketFmPage from '../Components/PocketFmPage/PocketFmPage';
import SocialMediaPage from '../Components/SocialMediaPage/SocialMediaPage';
import PerformMarketingPage from '../Components/PerformMarketingPage/PerformMarketingPage';
import PortFolio1Comp from '../Components/PortFolio/PortFolio1Comp';
import Services3Comp from '../Components/Services/Services3Comp';

const PortfolioPage = () => {
  const [currentPage, setCurrentPage] = useState('ContentCreation');
  const [showMenu, setShowMenu] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const sidebarRef = useRef(null);
  const dropdownRef = useRef(null); // Add ref for the dropdown button
  const headerHeight = 150; // Assuming header height is 100 pixels

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const changePage = (page) => {
    setCurrentPage(page);
    setShowMenu(false);
  };

  const renderPage = () => {
    switch (currentPage) {
      case 'PocketFmPage':
        return <Services3Comp />;
      case 'ContentCreation':
        return <ContentCreationPage />;
      case 'SocialMediaPromotions':
        return <SocialMediaPage />;
      case 'PerformanceMarketing':
        return <PerformMarketingPage />;
      case 'WebsiteDevelopment':
        return <WebsiteDevelopmentPage />;
      default:
        return <ContentCreationPage />;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sidebarTop = sidebarRef.current.getBoundingClientRect().top;

      // Set the top position of the sidebar based on the header's position
      if (sidebarTop <= headerHeight) {
        sidebarRef.current.style.top = `${headerHeight}px`;
      } else {
        sidebarRef.current.style.top = '0';
      }
      
      // Check if the dropdown button is visible
      const dropdownTop = dropdownRef.current.getBoundingClientRect().top;
      if (dropdownTop <= headerHeight) {
        dropdownRef.current.classList.add('sticky-bar');
      } else {
        dropdownRef.current.classList.remove('sticky-bar');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {/* Choose Category Bar */}
      <div ref={dropdownRef} className="  md:hidden z-10 bg-[#333333] text-black p-1 portfolio-dropdown">
      <ul className=' bg-[]'>
      <div className="flex items-center     ">
        
          <div
            className="cursor-pointer mt-3  text-[#69ff3b] rounded-full "
            onClick={() => setShowMenu(!showMenu)}
          >
            Choose Category <FontAwesomeIcon className='Portfolio-Main ml-2' icon={faAngleDown} />
          </div>
        </div>
        
      </ul>
        
        {/* Category Options */}
        <div className={` ${showMenu ? '' : 'hidden '}`}>
          <ul className=' justify-center'>
          <li className={`mb-4 cursor-pointer text-[#ffffff] ${currentPage === 'PocketFmPage' ? 'portfolio-active text-[#FFFF00]' : ''}`} onClick={() => changePage('PocketFmPage')}>Pocket Fm</li>
            <li className={`mb-4 cursor-pointer text-[#ffffff] ${currentPage === 'ContentCreation' ? 'portfolio-active text-[#FFFF00]' : ''}`} onClick={() => changePage('ContentCreation')}>Content Creation</li>
            <li className={`mb-4 cursor-pointer text-[#ffffff] ${currentPage === 'SocialMediaPromotions' ? 'portfolio-active text-[#FFFF00]' : ''}`} onClick={() => changePage('SocialMediaPromotions')}>Social Media Promotions</li>
            <li className={`mb-4 cursor-pointer text-[#ffffff] ${currentPage === 'PerformanceMarketing' ? 'portfolio-active text-[#FFFF00]' : ''}`} onClick={() => changePage('PerformanceMarketing')}>Performance Marketing</li>
            {/* <li className={`mb-4 cursor-pointer text-[#ffffff] ${currentPage === 'WebsiteDevelopment' ? 'portfolio-active text-[#FFFF00]' : ''}`} onClick={() => changePage('WebsiteDevelopment')}>Website Development</li> */}
          </ul>
        </div>
      </div>

      <div class="flex flex-col md:flex-row portfolio-Main w-full md:w-4/5 mx-auto portfilio-desktop">
        {/* Sidebar */}
        <div ref={sidebarRef} className={`w-full md:w-1/5 md:sticky h-screen mt-8 p-0 overflow-y-auto hide-for-mobile ${showMenu ? '' : 'hidden'} md:block`}>
          <ul>
          <li className={`mb-4 cursor-pointer text-[#999999] ${currentPage === 'PocketFmPage' ? 'portfolio-active' : ''}`} onClick={() => changePage('PocketFmPage')}>Pocket Fm</li>
        
            <li className={`mb-4 cursor-pointer text-[#999999] ${currentPage === 'ContentCreation' ? ' portfolio-active text-[#7fff07]' : ''}`} onClick={() => changePage('ContentCreation')}>Content Creation</li>
            <li className={`mb-4 cursor-pointer text-[#999999] ${currentPage === 'SocialMediaPromotions' ? ' portfolio-active text-[#7fff07]' : ''}`} onClick={() => changePage('SocialMediaPromotions')}>Social Media Promotions</li>
            <li className={`mb-4 cursor-pointer text-[#999999] ${currentPage === 'PerformanceMarketing' ? ' portfolio-active text-[#7fff07]' : ''}`} onClick={() => changePage('PerformanceMarketing')}>Performance Marketing</li>
            {/* <li className={`mb-4 cursor-pointer text-[#999999] ${currentPage === 'WebsiteDevelopment' ? ' portfolio-active text-[#7fff07]' : ''}`} onClick={() => changePage('WebsiteDevelopment')}>Website Development</li> */}
          </ul>
        </div>
        {/* Content */}
        <div className="flex-1 p-0 mt-4 overflow-y-auto" style={{ marginTop: showMenu ? '0' : '3rem' }}>
          <div style={{ transform: `translateY(auto)` }}>
            {renderPage()}
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioPage;
