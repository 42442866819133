import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './ServiceHeroSection.css';

const ServiceHeroSection = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // animation duration
            easing: 'ease-in-out', // animation easing
            once: false, // whether animation should happen only once
        });
    }, []);

    return (
        <div className="hero-container">
            <div className="image-slider">
                <div className="image-slide" style={{ backgroundImage: 'url("https://www.yudiz.com/codepen/studio-r/bg-living.jpg")' }}>
                    <div className="overlay"></div>
                </div>
                <div className="image-slide" style={{ backgroundImage: 'url("https://www.yudiz.com/codepen/studio-r/bg-kitchen.jpg")' }}>
                    <div className="overlay"></div>
                </div>
                <div className="image-slide" style={{ backgroundImage: 'url("https://www.yudiz.com/codepen/studio-r/bg-bedroom.jpg")' }}>
                    <div className="overlay"></div>
                </div>
                <div className="image-slide" style={{ backgroundImage: 'url("https://www.yudiz.com/codepen/studio-r/bg-office.jpg")' }}>
                    <div className="overlay"></div>
                </div>
            </div>
            <div className="hero-content">
                <h2 data-aos="fade-down">Your <span id='green'>business</span> is about to get a<br/> whole lot more <span id='green'>interesting</span></h2>
                <p data-aos="fade-up">Attract attention, inspire, and deliver results with <br/>Swayambhu Strategies.</p>
                <a href="/contact-us" className="get-in-touch-button" data-aos="fade-up">Contact Us</a>
            </div>
        </div>
    );
}

export default ServiceHeroSection;
