import React, { useEffect } from 'react';
import './HomeAbout.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faTasks, faAward } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';

const points = [
  {
    icon: faBrain,
    title: 'Innovative Leadership',
    description: 'Swayambhu Strategies has been a pioneer in media innovation for over 15 years, continuously pushing boundaries with AI-generated content solutions.'
  },
  {
    icon: faTasks,
    title: 'Comprehensive Services',
    description: 'Our range of content-related services caters to the diverse and evolving needs of the industry, ensuring complete solutions for our clients.'
  },
  {
    icon: faAward,
    title: 'Excellence in Media Solutions',
    description: 'We aspire to set new standards of excellence as the foremost media solution company, consistently delivering exceptional AI-generated content solutions.'
  }
];

const HomeAbout = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of the animation
      once: false,    // whether animation should happen only once
    });
  }, []);

  return (
    <div className="home-about-container">
      <div className="about-text" data-aos="fade-up" data-aos-delay="200">
        <p>Swayambhu Strategies has been at the forefront of media innovation for the past 15 years. Our vision is to be the leading media solution company, providing comprehensive content-related services that meet the ever-evolving demands of the industry. Our mission is to pioneer AI-generated media and content solutions that set new standards of excellence.</p>
      </div>
      <div className="point-boxes">
        {points.map((point, index) => (
          <div key={index} className="point-box" data-aos="fade-up" data-aos-delay={`${300 + index * 100}`}>
            <FontAwesomeIcon icon={point.icon} className="point-icon" />
            <h3 className="point-title">{point.title}</h3>
            <hr className="point-divider" />
            <p className="point-description">{point.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeAbout;
