import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './AboutUs1Comp.css';
import localGif from '../assets/aboutgif.gif';

const AboutUs1Comp = () => {
    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: 'ease-in-out',
            once: false,
        });
    }, []);

    return (
        <>
            <h2 className='about-us-h2'>Why Choose Us</h2>
            <div className="about-us-container" data-aos="fade-up" data-aos-delay="100">
                <div className="about-us-content">
                    <p>
                        Swayambhu Strategies is dedicated to crafting compelling and engaging video content across a variety of platforms, including television, OTT, social media, and feature films.
                        Our mission is to blend creative vision with technical expertise to deliver content that not only meets but exceeds the expectations of our audiences and clients.
                        We are here as a social media marketing agency to offer creative solutions to our clients.
                        We provide services such as design, advertising, digital marketing, content creation, and event management to increase brand awareness, reach target audience, and boost sales.
                    </p>
                </div>
                <div className="gif-container" data-aos="fade-up" data-aos-delay="300">
                    <img src={localGif} alt="About Us GIF" className="local-gif" />
                </div>
            </div>
        </>
    );
}

export default AboutUs1Comp;
