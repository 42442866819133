import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


import './App.css';

import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import ScrollTotopArrow from "./Components/ScrollToTopButton/ScrollToTopButton";
import ContactUs from "./Pages/ContactUs";
import Services from "./Pages/Services";
import ProjectDetailsPage from "./Components/Services/ProjectDetailsPage";
import projectData from './Components/Services/projectData.json';
import Careers from "./Pages/Careers";
import Portfolio from "./Pages/Portfolio";
import PortfolioDetails from "./Components/PortFolio/PortfolioDetails";
import PortfolioPage from "./Pages/PortfolioPage";
import ContentCreation from './Components/ContentCreation/ContentCreation'
import MediaPromotion from "./Components/MediaPramotion/MediaPramotion";
import PerformanceMarketing from "./Components/PerformanceMarketing/PerformanceMarketing";
import WebDev from "./Components/WebDev/WebDev";
import PocketFmPage from "./Components/PocketFmPage/PocketFmPage";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      
     
  
      <div className="">
      <ScrollTotopArrow/>
        <Header />
        <div className="pt-20">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/content-creation" element={<ContentCreation/>} />
          <Route path="/social-mediapramotions" element={<MediaPromotion/>} />
          <Route path="/performance-marketing" element={<PerformanceMarketing/>} />
          <Route path="/website-development" element={<WebDev/>} />
          <Route path="/pocket-fm" element={<PocketFmPage/>} />





         
        

          <Route path="/portfolio-details/:id" element={<PortfolioDetails projectData={projectData} />} />



          <Route
            exact
            path="/project/:id"
            element={<ProjectDetailsPage projectData={projectData} />} 
          />
        </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
