import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './WebDev.css';
import GetStarted from '../GetStarted/GetStarted';

const WebDev = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration
      easing: 'ease-in-out', // animation easing
      once: false, // animation should happen every time you scroll
    });
  }, []);

  return (
    <>
      <div className="webdev-body">
        <section className="webdev-hero">
          <div className="webdev-hero-content" data-aos="fade-up" data-aos-delay="200">
            <h1 className="webdev-hero-title">Web Development Services</h1>
            <p className="webdev-hero-description">We create innovative and responsive websites that deliver outstanding user experiences and drive engagement.</p>
          </div>
          <svg className="webdev-diagonal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon fill="#1e1e1e" points="-0,100 -100,0 100,100"></polygon>
          </svg>
        </section>

        <section className="webdev-service-content">
          <div className="webdev-left-content">
            <img className="webdev-img" src="http://frontend-tricks.com/wp-content/uploads/svg/code-icon.svg" alt="Code Icon"/>
          </div>
          <div className="webdev-right-content">
            <h1 className="webdev-title">Creative Websites</h1>
            <p className="webdev-description">We combine the latest technology with innovative design to create robust, interactive, and unique websites. Our approach ensures that your site not only looks great but also performs well, driving user engagement and conversions.</p>
          </div>
          <svg className="webdev-diagonal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon fill="#dcdbdb" points="0,100 100,0 100,100"></polygon>
          </svg>
        </section>

        <section className="webdev-service-content-two">
          <svg className="webdev-diagonal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon fill="#fff" points="-0,100 -100,0 100,100"></polygon>
          </svg>
          <div className="webdev-left-content">
            <img className="webdev-img" src="http://frontend-tricks.com/wp-content/uploads/svg/brush-icon.svg" alt="Brush Icon"/>
          </div>
          <div className="webdev-right-content">
            <h1 className="webdev-title" id='black-title'>Responsive Websites</h1>
            <p className="webdev-description" id='black-title'>We prioritize mobile-first design principles, ensuring seamless user experiences across various screen sizes. Our approach emphasizes responsive design, guaranteeing optimal performance and accessibility on all devices.</p>
          </div>
        </section>

        <section className="webdev-service-content">
          <div className="webdev-left-content">
            <img className="webdev-img" src="http://frontend-tricks.com/wp-content/uploads/svg/chart-icon.svg" alt="Chart Icon"/>
          </div>
          <div className="webdev-right-content">
            <h1 className="webdev-title">SEO-Compliant Websites</h1>
            <p className="webdev-description">Our web development services focus on creating websites optimized for search engines. We ensure keyword integration, compelling meta tags, and mobile-friendly designs, all of which improve your site's visibility and attract more organic traffic.</p>
          </div>
          <svg className="webdev-diagonal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon fill="#dcdbdb" points="0,100 100,0 100,100"></polygon>
          </svg>
        </section>

        <section className="webdev-service-content-four">
          <svg className="webdev-diagonal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon fill="#fff" points="-0,100 -100,0 100,100"></polygon>
          </svg>
          <div className="webdev-left-content">
            <img className="webdev-img" src="http://frontend-tricks.com/wp-content/uploads/svg/file-icon.svg" alt="File Icon"/>
          </div>
          <div className="webdev-right-content">
            <h1 className="webdev-title reduce-size" id='black-title'>Brand Representation</h1>
            <p className="webdev-description" id='black-title'>We combine the latest technology with innovative design to create robust, interactive, and unique websites. Our approach ensures that your site not only looks great but also performs well, driving user engagement and conversions.</p>
          </div>
        </section>
      </div>
      <GetStarted/>
    </>
  );
};

export default WebDev;
