import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import projectData from './projectData.json';
import './ProjectDetailsPage.css';
import grid1 from '../assets/about-banner.jpg';
import grid2 from '../assets/collab.jpg';
import grid3 from '../assets/about-banner.jpg';
import grid4 from '../assets/about-banner.jpg';
import grid5 from '../assets/about-banner.jpg';
import grid6 from '../assets/about-banner.jpg';
import grid7 from '../assets/about-banner.jpg';
import grid9 from '../assets/about-banner.jpg';
import grid10 from '../assets/about-banner.jpg';

const imagesMap = {
  grid1,
  grid2,
  grid3,
  grid4,
  grid5,
  grid6,
  grid7,
  grid9,
  grid10
};

const ProjectDetailsPage = () => {
  const { id } = useParams();
  const project = projectData.find((proj, index) => index === parseInt(id, 10) - 1);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % project.images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(intervalId);
  }, [project.images.length]);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div className="project-details-container content">
      <div className="top-section">
        <div className="project-name">
          <h2>{project.name}</h2>
        </div>
        <div className="project-slideshow">
          <img
            src={imagesMap[project.images[currentImageIndex]]}
            alt={`Project ${currentImageIndex + 1}`}
            className="slideshow-image"
          />
        </div>
      </div>
      <hr />
      <div className="bottom-section">
        <div className="project-about">
          <h3>About Project</h3>
          <p>{project.about}</p>
        </div>
        <div className="project-images-grid">
          {project.images.map((imageKey, index) => (
            <img key={index} src={imagesMap[imageKey]} alt={`Project ${index + 1}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsPage;
