import React, { useEffect } from 'react';
import './ContentCreation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faHeadphones, faTv, faFilm } from '@fortawesome/free-solid-svg-icons';
import ContentGallery from './ContentGallery';
import heroImage from '../assets/content-heroimage.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

const services = [
  {
    icon: faGlobe,
    title: 'OTTs (Over-The-Top Platforms)',
    description: 'Leverage our expertise in creating compelling content for OTT platforms. We help you engage your audience with original series, movies, documentaries, and more.'
  },
  {
    icon: faHeadphones,
    title: 'Audio Platforms',
    description: 'Explore the world of audio content with our production services. From podcasts to audio dramas, we bring your stories to life through sound.'
  },
  {
    icon: faTv,
    title: 'TV',
    description: 'With years of experience in TV content creation, we understand the dynamics of the television industry. Let us help you produce captivating shows that resonate with viewers.'
  },
  {
    icon: faFilm,
    title: 'Silver Screen',
    description: 'Take your storytelling to the big screen with our film production services. From scriptwriting to post-production, we provide end-to-end solutions for your cinematic projects.'
  }
];

const ContentCreation = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: false,
    });
  }, []);

  return (
    <>
      <div className="media-promotion-hero" data-aos="fade-up" data-aos-delay="100">
        <img src={heroImage} alt="Social Media Promotion" className="media-promotion-hero-image" />
        <div className="media-promotion-hero-overlay"></div>
        <div className="media-promotion-hero-content">
          <h1 className="media-promotion-hero-title">We Create High-Quality Content for OTT, TV, Film, and Social Media</h1>
        </div>
      </div>
      <div className="content-creation-container" data-aos="fade-up" data-aos-delay="200">
        <h1 className="section-title">Content Creation Services</h1>
        <p className="section-description">
          We specialize in creating captivating and high-quality content across various platforms. Our team of experienced professionals ensures that each project is executed with precision and creativity to meet the unique needs of our clients. Whether you're looking to create content for OTT platforms, audio productions, TV shows, films, or social media, we've got you covered.
        </p>
        <div className="service-boxes">
          {services.map((point, index) => (
            <div key={index} className="service-box" data-aos="fade-up" data-aos-delay={300 + index * 100}>
              <FontAwesomeIcon icon={point.icon} className="service-icon" />
              <h3 className="service-title">{point.title}</h3>
              <hr className="service-divider" />
              <p className="service-description">{point.description}</p>
            </div>
          ))}
        </div>
      </div>
      <ContentGallery />
    </>
  );
}

export default ContentCreation;
