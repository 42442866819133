import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './AboutUs3Comp.css';

const AboutUs3Comp = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: false,
        });
    }, []);

    return (
        <div className="about-us3-container" data-aos="fade-up" data-aos-delay="200">
            <div className="about-us3-content">
                <h2 data-aos="fade-up" data-aos-delay="200">Vision & Mission</h2>
                <p data-aos="fade-up" data-aos-delay="300">
                    "Our vision is to be the go-to media solution company for all content-related needs. We aim to set trends in AI-generated media, offering innovative solutions that drive success for our clients. Our mission is to continuously push the boundaries of what's possible in media and technology, ensuring our clients stay ahead of the curve."
                </p>
            </div>
            <div className="gif-container" data-aos="fade-up" data-aos-delay="400">
                <img src={require('../assets/taxi-target-with-an-arrow.gif')} alt="Mission GIF" className="mission-gif" />
            </div>
        </div>
    );
}

export default AboutUs3Comp;
