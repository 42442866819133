import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import './GetStarted.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const GetStarted = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="get-started-wrapper">
      <div className="get-started-overlay"></div>
      <div className="get-started-content">
        <div className="get-started-vertical-line" data-aos="fade-up" data-aos-delay="200"></div>
        <div className="get-started-social-icons">
          <div className="get-started-icon-circle" data-aos="fade-up" data-aos-delay="300">
            <FontAwesomeIcon icon={faLinkedin} className="get-started-social-icon" />
          </div>
          <div className="get-started-icon-circle" data-aos="fade-up" data-aos-delay="400">
            <FontAwesomeIcon icon={faYoutube} className="get-started-social-icon" />
          </div>
        </div>
        <div className="get-started-text-section" data-aos="fade-up" data-aos-delay="500">
          <p>Have any projects in mind?</p>
          <Link to="/contact-us">
            <button className="get-started-button">GET STARTED</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
