import React, { useEffect } from 'react';
import './ContactUs2Comp.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ContactUs2Comp = () => {
    useEffect(() => {
        AOS.init({
            once: false, // Animation occurs only once
            duration: 1000, // Animation duration
            delay: 200, // Delay for each animation
        });
    }, []);

    return (
        <div className="maps-container" data-aos="fade-up">
            <div className="details-container">
                <div className="info-container" data-aos="fade-up" data-aos-delay="200">
                    <i className="fas fa-map-marker-alt"></i>
                    <div>
                        <h5 className="maps-mini-title">Address</h5>
                        <p>
                        D4-102, Nyati Equatorial Phase 2 <br />
                            Bavdhan, Pune - 411021
                        </p>
                    </div>
                </div>
                <div className="info-container" data-aos="fade-up" data-aos-delay="400">
                    <i className="fas fa-phone-alt"></i>
                    <div>
                        <h5 className="maps-mini-title">Phone</h5>
                        <p>Priyanka Daithankar (+91-9763978086)</p>
                    </div>
                </div>
                <div className="info-container" data-aos="fade-up" data-aos-delay="600">
                    <i className="fas fa-envelope"></i>
                    <div>
                        <h5 className="maps-mini-title">Email</h5>
                        <p className='less-ft'>swayambhu@swayambhustrategy.com
</p>
                    </div>
                </div>
            </div>
            <div className="maps-frame" data-aos="fade-up" data-aos-delay="800">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.2324511375728!2d73.7647792751916!3d18.51839488257476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2be61069a56f3%3A0x3787803d04211fe3!2sNyati%20Equatorial%20phase%202!5e0!3m2!1sen!2sin!4v1716319819577!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                ></iframe>
            </div>
        </div>
    );
};

export default ContactUs2Comp;
