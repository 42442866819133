import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './AboutUs2Comp.css';
import teamGif from '../assets/GODDAMNTEAM.gif';

const AboutUs2Comp = () => {
    useEffect(() => {
        AOS.init({
            duration: 800, // Animation duration
            easing: 'ease-in-out', // Easing function for animation
            once: false, // Whether animation should happen only once
        });
    }, []);

    return (
        <div className="about-us2-container">
            <div className="gif2-container" data-aos="fade-up" data-aos-delay="200">
                <img src={teamGif} alt="Team GIF" className="team-gif" />
            </div>
            <div className="about-us2-content" data-aos="fade-up" data-aos-delay="400">
                <h2 data-aos="fade-up" data-aos-delay="300">Our Team</h2>
                <p data-aos="fade-up" data-aos-delay="500">
                    Our team is our greatest asset. Comprising experts in content creation, marketing, and technology, we bring diverse skills and perspectives to every project. From creative directors to AI specialists, our team works cohesively to deliver outstanding results.
                </p>
            </div>
        </div>
    );
}

export default AboutUs2Comp;
