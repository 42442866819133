import React from 'react';
import './WhatWeDo.css';

const WhatWeDo = () => {
    return (
        <>
        <div>
        <h1 className='What-we-do-title'>What we do ?</h1>
        </div>
        <div className="what-we-do-container">
       
            <div className="what-we-do-box">
                <div className="what-we-do-content">
                    <h2>01</h2>
                    <h3>Target <br/>Audience
</h3>
                    <p>
                    Identifying a target audience involves researching demographics, psychographics, and behavior patterns to understand their needs, preferences, and buying habits, informing marketing decisions.

                    </p>
                    {/* <a href="#">Read More</a> */}
                </div>
            </div>

            <div className="what-we-do-box">
                <div className="what-we-do-content">
                    <h2>02</h2>
                    <h3>Marketing Strategy
</h3>
                    <p>
                    Developing a marketing strategy involves understanding your target audience, crafting a unique value proposition, selecting appropriate channels, and measuring and adapting tactics.

                    </p>
                    {/* <a href="#">Read More</a> */}
                </div>
            </div>

            <div className="what-we-do-box">
                <div className="what-we-do-content">
                    <h2>03</h2>
                    <h3>Content
Marketing
</h3>
                    <p>
                    Content marketing involves creating and sharing valuable, relevant, and consistent content to attract and retain a clearly defined audience and drive profitable customer action.

                    </p>
                    {/* <a href="#">Read More</a> */}
                </div>
            </div>

           
        </div>
        </>
    );
}

export default WhatWeDo;
