import React from 'react';
import './MediaPromotion.css';
import heroImage from '../assets/media-banner.jpg'; // Make sure to replace with the actual path to your hero image
import promoGif from '../assets/social-media-gif.gif'; // Path to your GIF file

const MediaPromotion = () => {
  return (
    <div className="media-promotion-container">
      {/* Hero Image Section */}
      <div className="media-promotion-hero">
        <img src={heroImage} alt="Social Media Promotion" className="media-promotion-hero-image" />
        <div className="media-promotion-hero-overlay"></div>
        <div className="media-promotion-hero-content">
          <h1 className="media-promotion-hero-title">Enhance Your Brand with AI-Driven Media Promotions</h1>
        </div>
      </div>

      {/* Description Section */}
      <div className="media-promotion-description">
        <div className="media-promotion-description-content">
          <img src={promoGif} alt="Promotional GIF" className="media-promotion-gif" />
          <div className="media-promotion-text">
            <p className="media-promotion-section-description">
              At Swayambhu Strategies, we specialize in creating high-quality content for various social media platforms, including LinkedIn, Facebook, Instagram, Twitter, and others. Our unique approach leverages the power of AI to optimize content for maximum engagement and effectiveness.
              <br /><br />
              We produce targeted advertisements for YouTube and TV, utilizing AI to enhance the creative process and deliver superior results. Our AI-driven strategies ensure your promotions are impactful, reaching the right audience at the right time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaPromotion;
