import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../../assets/logou-removebg-preview.png';
import './Header.css';

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();

  const handleToggle = () => setExpanded(!expanded);
  const closeNavbar = () => setExpanded(false);

  const servicesPaths = [
    '/content-creation',
    '/performance-marketing',
    '/social-mediapramotions',
    '/website-development',
  ];

  const isServicesPathActive = servicesPaths.includes(location.pathname);

  return (
    <div className='main-header'>
      <div className='inner-header'>
        <Navbar expand="lg" className='header-container navbar-dark p-0' expanded={expanded}>
          <Navbar.Brand href="/" className='header-left'>
            <img src={logo} alt="Logo" className="logo" />
            <span className="organization-name">Swayambhu Strategies</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}>
            {expanded ? (
              <FontAwesomeIcon className='header-fatime' icon={faTimes} style={{ color: 'white', border: 'none !important' }} />
            ) : (
              <FontAwesomeIcon className='header-fabar' icon={faBars} style={{ color: 'white' }} />
            )}
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto header-right">
              <Nav.Link as={NavLink} exact to="/" activeClassName="active" onClick={closeNavbar}>Home</Nav.Link>
              <Nav.Link as={NavLink} to="/about-us" activeClassName="active" onClick={closeNavbar}>About Us</Nav.Link>
              <NavDropdown
                title={<span>Services <FontAwesomeIcon icon={faAngleDown} /></span>}
                id="basic-nav-dropdown"
                className={isServicesPathActive ? 'active' : ''}
                
              >
                <NavDropdown.Item as={NavLink} to="/content-creation" activeClassName="active" onClick={closeNavbar}>Content Creation</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/performance-marketing" activeClassName="active" onClick={closeNavbar}>Performance Marketing</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/social-mediapramotions" activeClassName="active" onClick={closeNavbar}>Social Media Promotions</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/website-development" activeClassName="active" onClick={closeNavbar}>Web Development</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={NavLink} to="/portfolio" activeClassName="active" onClick={closeNavbar}>Portfolio</Nav.Link>
              <Nav.Link as={NavLink} to="/careers" activeClassName="active" onClick={closeNavbar}>Careers</Nav.Link>
              <Nav.Link as={NavLink} to="/contact-us" activeClassName="active" onClick={closeNavbar}>Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;
