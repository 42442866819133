import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Home8Comp.css';
import Img2 from '../../assets/pocketFM/4.webp'
import Img1 from '../../assets/pocketFM/23.webp'
import Img3 from '../../assets/pocketFM/25.webp'
import Img4 from '../../assets/pocketFM/37.webp'

const Home8Comp = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, 
            easing: 'ease-in-out', 
            once: false, 
        });
    }, []);

    return (
        <div className="home-container">
            <div className="home-contentLeft">
                <div className="home-row">
                    <div className="home-imgWrapper" data-aos="fade-up" data-aos-delay="100">
                        <img src={Img1} alt="" />
                    </div>
                    <div className="home-imgWrapper" data-aos="fade-up" data-aos-delay="200">
                        <img src={Img2} alt="" />
                    </div>
                    <div className="home-imgWrapper" data-aos="fade-up" data-aos-delay="300">
                        <img src={Img3} alt="" />
                    </div>
                    <div className="home-imgWrapper" data-aos="fade-up" data-aos-delay="400">
                        <img src={Img4} alt="" />
                    </div>
                </div>
            </div>
            <div className="home-contentRight">
                <div className="home-content" data-aos="fade-up" data-aos-delay="500">
                    <h4>Welcome to</h4>
                    <h2>Swayambhu Strategies...</h2>
                    <p>Your ultimate destination for innovative and AI-driven media content solutions. With over 15 years of experience, we transform your ideas into compelling stories across OTT platforms, audio channels, TV, and the silver screen. Our expertise in social media promotions and website development ensures your brand stands out in the digital landscape. Join us as we set new trends in AI-generated media.</p>

                    <Link className='readmore-link' to="/about-us">
                    <a className='read-more-button' href="">Read More...</a>
           
          </Link>
                    
                    
                </div>
            </div>
        </div>
    );
};

export default Home8Comp;
