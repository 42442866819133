import React, { useEffect } from 'react';
import './Description.css';
import image1 from '../../assets/desc-1.png';
import image2 from '../../assets/desc-2.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Description = () => {
    useEffect(() => {
        AOS.init({
            duration: 800, // Animation duration
            easing: 'ease-in-out', // Easing function for animation
            once: false, // Whether animation should happen only once
        });
    }, []);

    return (
        <div className="description-container">
            <div className="description-part" data-aos="fade-up" data-aos-delay="100">
                <img src={image1} alt="Well equipped studio and setup" className="description-image zoomer-mover" />
                <div className="image-title">Well equipped studio and setup</div>
            </div>
            <div className="description-part" data-aos="fade-up" data-aos-delay="300">
                <img src={image2} alt="Experts and best from the industry" className="description-image zoomer-mover" />
                <div className="image-title">Experts and best from the industry</div>
            </div>
            <div className="description-part description-text" data-aos="fade-up" data-aos-delay="500">
                Our company operates with a flexible model, leveraging a collaborative team of talented freelancers from various disciplines within the media production industry. We cater to different requirements in multimedia.
            </div>
        </div>
    );
}

export default Description;
