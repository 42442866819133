import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Service1Comp.css';

const Service1Comp = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // animation duration
            easing: 'ease-in-out', // animation easing
            once: false, // animation should happen every time you scroll
        });
    }, []);

    return (
        <section className="Service-container">
        <h2 className='services-heading'>Discover Services We Provide !</h2>
            <section className="card__container">
                <div className="card__bx" style={{ '--clr': '#89ec5b' }} data-aos="fade-up" data-aos-delay="100">
                    <div className="card__data">
                        <div className="card__icon">
                            <i className="fa-solid fa-film"></i>
                        </div>
                        <div className="card__content">
                            <h3>Content Creation</h3>
                            <p>We create engaging content for OTT, audio, TV shows, films, and social media to captivate audiences and drive engagement.</p>
                            <Link to="/content-creation">
                            <a href="">Read More</a>           
          </Link>
                           
                        </div>
                    </div>
                </div>
                <div className="card__bx" style={{ '--clr': '#eb5ae5' }} data-aos="fade-up" data-aos-delay="300">
                    <div className="card__data">
                        <div className="card__icon">
                            <i className="fa-solid fa-users"></i>
                        </div>
                        <div className="card__content">
                            <h3>Social Media Promotions</h3>
                            <p className='card-para'>Our team crafts campaigns that resonate with your audience, enhancing your brand's online presence with data-driven insights.</p>
                            <Link to="/social-mediapramotions">
                            <a href="">Read More</a>
           
          </Link>
                            
                        </div>
                    </div>
                </div>
                <div className="card__bx" style={{ '--clr': '#5b98eb' }} data-aos="fade-up" data-aos-delay="500">
                    <div className="card__data">
                        <div className="card__icon">
                            <i className="fa-solid fa-chart-line"></i>
                        </div>
                        <div className="card__content">
                            <h3>Performance Marketing</h3>
                            <p className='card-para'>We deliver measurable results with campaigns designed to optimize ROI using advanced analytics and data-backed decisions.</p>
                            <Link to="/performance-marketing">
                            <a href="">Read More</a>
           
          </Link>
                           
                        </div>
                    </div>
                </div>
                <div className="card__bx" style={{ '--clr': '#f8c852' }} data-aos="fade-up" data-aos-delay="600">
                    <div className="card__data">
                        <div className="card__icon">
                            <i className="fa-solid fa-laptop-code"></i>
                        </div>
                        <div className="card__content">
                            <h3>Website Development</h3>
                            <p className='card-para'>We create SEO-compliant, user-friendly websites that represent your brand and engage visitors, driving conversions with creative design and technology.</p>
                            <Link to="/website-development">
                            <a href="">Read More</a>
           
          </Link>

                           
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
}

export default Service1Comp;
