import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './CareersHeroSection.css';

// Import images
import careerImage1 from '../assets/career-1.avif';
import careerImage2 from '../assets/career-2.jpg';
import careerImage4 from '../assets/career-2.jpg';
import careerImage3 from '../assets/career-4.jpg';

const CareersHeroSection = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // animation duration
            easing: 'ease-in-out', // animation easing
            once: true, // whether animation should happen only once
        });
    }, []);

    return (
        <div className="Career-hero-container">
            <div className="Career-image-slider">
                <div className="Career-image-slide" style={{ backgroundImage: `url(${careerImage1})` }}>
                    <div className="Career-overlay"></div>
                </div>
                <div className="Career-image-slide" style={{ backgroundImage: `url(${careerImage2})` }}>
                    <div className="Career-overlay"></div>
                </div>
                <div className="Career-image-slide" style={{ backgroundImage: `url(${careerImage3})` }}>
                    <div className="Career-overlay"></div>
                </div>
                <div className="Career-image-slide" style={{ backgroundImage: `url(${careerImage4})` }}>
                    <div className="Career-overlay"></div>
                </div>
            </div>
            <div className="Career-hero-content">
                <h2 id='yellow' data-aos="fade-down">Life at Swayambhu Strategies</h2>
                <p data-aos="fade-up">Discover what it’s like to work at Swayambhu Strategies. Our vibrant company culture, employee testimonials, and team event photos offer a glimpse into life at our company.</p>
               
                <p data-aos="fade-up">Join our team and be part of a dynamic and innovative company. Explore our current job openings and find the perfect opportunity for you.</p>
            </div>
        </div>
    );
}

export default CareersHeroSection;
