import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './AboutIntro.css';
import teamGif from '../assets/about-us-gif.gif';

const AboutIntro = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: false,
        });
    }, []);

    return (
        <div className="about-intro-container">
            <div className="gif-containers" data-aos="fade-down" data-aos-delay="100">
                <img src={teamGif} alt="Team GIF" className="team-gif" />
            </div>
            <div className="about-content-box" data-aos="fade-up" data-aos-delay="300">
                <p>Founded in Pune, <span>Swayambhu Strategies</span> has grown from a small team of passionate media professionals to a renowned content creation powerhouse. Our journey began with a simple mission  to revolutionize the way media content is created and consumed. Over the years, we have collaborated with industry giants like <span className='highlight'>Star TV</span>, <span className='highlight'>Zee TV</span>, and <span className='highlight'>Amazon Prime</span>, delivering exceptional content that resonates with audiences.</p>
            </div>
        </div>
    );
}

export default AboutIntro;
