import React from 'react';
import './Home.css'
import ContactUs1Comp from '../Components/ContactUs/ContactUs1Comp';
import ContactUs2Comp from '../Components/ContactUs/ContactUs2Comp';
import WeLookForward from '../Components/ContactUs/WeLookForward';
function ContactUs() {
  return (
    <div className="content">
    <WeLookForward/>
     <ContactUs1Comp/>
     <ContactUs2Comp/>
    </div>
  );
}

export default ContactUs;
