import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Img from "../assets/whychoose.png"
import './Timeline.css'; // Import your CSS file

const Timeline = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration
      easing: 'ease-in-out', // animation easing
      once: false, // animation should happen every time you scroll
    });
  }, []);

  return (
    <div className="container-fluid blue-bg mt-4" data-aos="fade-up" data-aos-delay="100">
    <div className='case-study-container'>
    <h1 className='case-study-title'>Capabilities <br/> &  Experience 
</h1>
    {/* <img className='case-study-image' src={Img}></img> */}
    </div>
          

      <div className="container">
      {/* First section */}
        <div className="row align-items-center how-it-works">
          <div className="col-2 text-center bottom">
            <div className="circle">1</div>
          </div>
          <div className="col-6 timeline-content">
            <h5>RADIO AND TELEVISION</h5>
            <p>Our founder has substantial backgrounds in these areas, having created popular content for both mediums, ensuring wide reach and engagement.
            </p>
          </div>
        </div>

        {/* Path between 1-2 */}
        <div className="row timeline" data-aos="fade-up" data-aos-delay="200">
          <div className="col-2">
            <div className="corner top-right"></div>
          </div>
          <div className="col-8">
            <hr />
          </div>
          <div className="col-2">
            <div className="corner left-bottom"></div>
          </div>
        </div>

        {/* Second section */}
        <div className="row align-items-center justify-content-end how-it-works" data-aos="fade-up" data-aos-delay="300">
          <div className="col-6 text-right timeline-content">
            <h5>OTT AND FEATURE FILMS</h5>
            <p>Our projects like "Half Pants Full Pants" and films like "Vijyeta" and "One Way Ticket" demonstrate our ability to adapt stories and production techniques to fit the requirements of OTT platforms and cinema audiences alike</p>
          </div>
          <div className="col-2 text-center full">
            <div className="circle">2</div>
          </div>
        </div>

        {/* Path between 2-3 */}
        <div className="row timeline" data-aos="fade-up" data-aos-delay="400">
          <div className="col-2">
            <div className="corner right-bottom timeline-content"></div>
          </div>
          <div className="col-8">
            <hr />
          </div>
          <div className="col-2">
            <div className="corner top-left"></div>
          </div>
        </div>

        {/* Third section */}
        <div className="row align-items-center how-it-works" data-aos="fade-up" data-aos-delay="500">
          <div className="col-2 text-center top">
            <div className="circle">3</div>
          </div>
          <div className="col-6 timeline-content">
            <h5>SOCIAL MEDIA MARKETING</h5>
            <p>Understanding the dynamics of social media platforms, we have successfully executed promotional campaigns that enhance visibility and engagement, directing traffic effectively to targeted content.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
