import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Image from '../assets/pramotion.webp';
import WhatWeDo from './WhatWeDo';

import './PerformanceMarketing.css';

const PerformanceMarketing = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: false,
    });
  }, []);

  return (
    <>
      <div className="performance-marketing-section">
        <div className="performance-marketing-left" data-aos="fade-up" data-aos-delay="100">
          <div className="performance-marketing-text-container">
            <h2 className="performance-marketing-about-heading">About</h2>
            <p className="performance-marketing-about-description">
              We are here as a social media marketing agency to offer creative solutions to our clients. We provide services such as design, advertising, digital marketing, content creation, and event management to increase brand awareness, reach target audience, and boost sales.
            </p>
          </div>
        </div>
        <div className="performance-marketing-right zoomer-mover" data-aos="fade-up" data-aos-delay="200">
          <img src={Image} alt="About Us" className="performance-marketing-about-image" />
        </div>
      </div>
      <WhatWeDo />
    </>
  );
};

export default PerformanceMarketing;
