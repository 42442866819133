import React, { useState, useEffect } from 'react';
import './ScrollToTopButton.css'; // Import your CSS file for styling

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

const ScrollTotopArrow = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className={`scroll-to-top zoomer-mover ${isVisible ? 'show' : ''}`} onClick={scrollToTop}>
 <FontAwesomeIcon className='up-icon ' icon={faAngleUp}  />    </div>
  );
};

export default  ScrollTotopArrow;
