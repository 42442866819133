import React from 'react';
import './Home.css'
import CareersHeroSection from '../Components/Careers/CareersHeroSection';
import Career1Comp from '../Components/Careers/Career1Comp';
import CareerIntro from '../Components/Careers/CareerIntro';

function Careers() {
  return (
    <div className="content">
  <CareersHeroSection/>
  <CareerIntro/>
  <Career1Comp/>
   
    </div>
  );
}

export default Careers;
