import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'; // Import the right arrow icon


const portfolioData = [
 
  {
    id: 7,
    title: 'Vijeta (Marathi Movie)',
    videoUrl: 'https://www.youtube.com/embed/uJuVpZ2jUwA',
  },
  {
    id: 8,
    title: 'Single (Hindi)',
    videoUrl: 'https://www.youtube.com/embed/Lhmam_ZRA48',
  },
  {
    id: 9,
    title: 'Amhi Doghe Raja Rani (Marathi Daily Soap)',
    videoUrl: 'https://www.youtube.com/embed/lTLFc0r6m58',
  },
  {
    id: 10,
    title: 'Shatada Prem Karave (Marathi Daily Soap)',
    videoUrl: 'https://www.youtube.com/embed/f-5RUi6u2z0',
  },
  {
    id: 11,
    title: 'Agnihotra (Marathi Daily Soap)',
    videoUrl: 'https://www.youtube.com/embed/ScoFSoPtdU4',
  },
  {
    id: 12,
    title: 'Vedantu (TV Commercial)',
    videoUrl: 'https://www.youtube.com/embed/zY6EYhiU2W8',
  },
];

const PerformMarketingPage = () => {
  return (
    <div className='gallery-container'>
      <div className="gallery-list">
        {portfolioData.map((project) => (
          <Link to={`/portfolio-details/${project.id}`} key={project.id} className="gallery-item-link">
            <div className="gallery-item">
              <iframe
                width="560"
                height="315"
                src={project.videoUrl}
                title={project.title}
                allowFullScreen
              ></iframe>
              <hr className="portfolio-divider" />
              <div className="read-more-container">
                <span className="read-more-texts">Read more</span>
                <FontAwesomeIcon icon={faArrowRight} className="read-more-icon" />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default PerformMarketingPage;
