import React from 'react';
import './Home.css'

import Home7Comp from '../Components/Home/Home7Comp/Home7Comp';
import Home8Comp from '../Components/Home/Home8Comp/Home8Comp';
import Home9Comp from '../Components/Home/Home9Comp/Home9Comp';
// import Home10Comp from '../Components/Home/Home10Comp/Home10Comp';
import Service1Comp from '../Components/Services/Service1Comp';
import HomeHeroSection from '../Components/Home/HomeHeroSection';
import ContactUs1Comp from '../Components/ContactUs/ContactUs1Comp';
import HomeAbout from '../Components/Home/HomeAbout';
import GetStarted from '../Components/GetStarted/GetStarted';
import WhatWeDo from '../Components/PerformanceMarketing/WhatWeDo';
import Home2Comp from '../Components/Home/Home2Comp/Home2Comp';
import Home5Comp from '../Components/Home/Home5Comp/Home5Comp';
function Home() {
  return (
    <div className="content">
 <HomeHeroSection/>
 
  
      <Home8Comp/>
      <HomeAbout/>
     
      <Home9Comp/>
      <Service1Comp/> 
      <WhatWeDo/>s
   
      
      {/* <Home10Comp/> */}
      
      <ContactUs1Comp/>
      <Home7Comp/>
      <GetStarted/>
     
      
    </div>
  );
}

export default Home;
