import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';
import './HomeHeroSection.css';
import bg6 from '../assets/ott-top-concept-providing-video-services-internet-digital-banner-concept-3d-render_507676-2491.jpg';
import bg3 from '../assets/audio.jpg';
import bg5 from '../assets/radio-advertising-illustration_335657-381.jpg';
import bg4 from '../assets/editing.jpg';
import bg1 from '../assets/ai-image.jpg';
import bg2 from '../assets/web-dev.jpg';

const HomeHeroSection = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, 
            easing: 'ease-in-out', 
            once: false, 
        });
    }, []);

    const images = [bg6, bg2, bg3, bg4, bg5, bg1];

    return (
        <div className="home-hero-container">
            {images.map((image, index) => (
                <div
                    key={index}
                    className="home-image-slide"
                    style={{
                        backgroundImage: `url(${image})`,
                    }}
                >
                    <div className="home-overlay"></div>
                </div>
            ))}
            <div className="home-hero-content">
                <h2 data-aos="fade-down">Transforming <span id='green'>Media</span> with AI-Driven Content <span id='green'>Solutions</span></h2>
                <Link to="/contact-us" className="get-in-touch-button" data-aos="fade-up">
      GET IN TOUCH
    </Link>            </div>
        </div>
    );
}

export default HomeHeroSection;

