import React, { useState, useEffect } from 'react';
import './Career1Comp.css';
import Image from '../assets/lounge-man-working-on-web-design.gif';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Career2Comp = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        cv: null
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.files ? e.target.files[0] : e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = new FormData();
        data.append('name', formData.name);
        data.append('email', formData.email);
        data.append('phone', formData.phone);
        data.append('cv', formData.cv);

        try {
            await axios.post('https://swayambhu-backend.vercel.app/api/careerForm/submit-career-form', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast.success('We will reach you out Soon!');
            setFormData({
                name: '',
                email: '',
                phone: '',
                cv: null
            });
        } catch (error) {
            console.error('Error submitting form', error);
            toast.error('Error submitting form');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // AOS initialization
        const AOS = require('aos');
        AOS.init({
            duration: 1000, // Animation duration
            easing: 'ease-in-out', // Easing option
            once: false, // Whether animation should occur only once
            mirror: false // Whether elements should animate out while scrolling past them
        });
    }, []);

    return (
        <div className="career2-container">
            <div className="career2-left" data-aos="fade-up">
                <img src={Image} alt="Image" className="career2-image" />
            </div>
            <div className="career2-right" data-aos="fade-up">
                <form className="career2-form" onSubmit={handleSubmit}>
                    <div className="form-group" data-aos="fade-up" data-aos-delay="100">
                        <label htmlFor="name">Full Name</label>
                        <input type="text" id="name" name="name" placeholder="Full Name" className="contact-input" value={formData.name} onChange={handleChange} />
                    </div>
                    <div className="form-group" data-aos="fade-up" data-aos-delay="200">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="emailid" name="email" placeholder="Email" className="contact-input" value={formData.email} onChange={handleChange} />
                    </div>
                    <div className="form-group" data-aos="fade-up" data-aos-delay="300">
                        <label htmlFor="phone">Phone</label>
                        <input type="tel" id="phone" name="phone" placeholder="Phone" className="contact-input" value={formData.phone} onChange={handleChange} />
                    </div>
                    <div className="form-group" data-aos="fade-up" data-aos-delay="400">
                        <label htmlFor="cv">Upload CV/Resume</label>
                        <input type="file" id="cv" name="cv" className="contact-input" onChange={handleChange} />
                    </div>
                    <button type="submit" className="submit-button" disabled={isLoading}>{isLoading ? 'Sending...' : 'Submit'}</button>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Career2Comp;
