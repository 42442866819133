import React, { useEffect } from 'react';
import './WeLookForward.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const WeLookForward = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: false, // Animation occurs only once
            delay: 200, // Delay between animations
        });
    }, []);

    return (
        <div className="forward-container" data-aos="fade-down">
            <div className="forward-title">WE LOOK FORWARD</div>
            <div className="forward-content">
                At Swayambhu Strategies, we combine our technical prowess with creative vision to create content that not only tells stories but also drives engagement and delivers results. Partnering with Pocket FM, we are confident in our ability to produce promotional videos that will enhance brand visibility and audience engagement on a large scale.
            </div>
            <div className="forward-info">
                For additional information you can reach out via below options.
            </div>
        </div>
    );
};

export default WeLookForward;
