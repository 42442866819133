import React from 'react';
import './Home.css'
import AboutUs1Comp from '../Components/AboutUs/AboutUs1Comp';
import AboutUs2Comp from '../Components/AboutUs/AboutUs2Comp';
import AboutUs3Comp from '../Components/AboutUs/AboutUs3Comp';
import AboutIntro from '../Components/AboutUs/AboutIntro ';
import Timeline from '../Components/AboutUs/Timeline';
import Team from '../Components/AboutUs/Team/Team';
import Description from '../Components/Home/Description/Description';
function AboutUs() {
  return (
    <div className="content">
    <AboutIntro/>
    <Description/>
    
     <AboutUs1Comp/>
     <Timeline/>
     
     <AboutUs3Comp/>
     <AboutUs2Comp/>
     {/* <Team/> */}
     {/* <OurTeam/> */}
     
    </div>
  );
}

export default AboutUs;
