import React from 'react';
import './CareerIntro.css';

const CareerIntro = () => {
    return (
        <div className="career-container">
            <div className="career-content">
                <h2 className="career-main-heading">Join Us</h2>
                <p className="career-message">
                    To apply, please email your resume at <a href="mailto:swayambhu@swayambhustrategy.com
">swayambhu@swayambhustrategy.com
</a> or You Can fill up the form below.
                </p>
            </div>
        </div>
    );
};

export default CareerIntro;
