import React, { useEffect } from 'react';
import './Services3Comp.css';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import image1 from '../assets/pocketFM/1.jpeg';
import image2 from '../assets/pocketFM/2.jpeg';
import image3 from '../assets/pocketFM/3.jpeg';
import image4 from '../assets/pocketFM/4.jpeg';
import image5 from '../assets/pocketFM/5.jpeg';
import image6 from '../assets/pocketFM/6.jpeg';
import image7 from '../assets/pocketFM/7.jpeg';
import image8 from '../assets/pocketFM/8.jpeg';
import image9 from '../assets/pocketFM/9.jpeg';
import image10 from '../assets/pocketFM/10.jpeg';
import image11 from '../assets/pocketFM/11.jpeg';
import image12 from '../assets/pocketFM/12.jpeg';
import image13 from '../assets/pocketFM/13.jpeg';
import image14 from '../assets/pocketFM/14.jpeg';
import image15 from '../assets/pocketFM/15.jpeg';
import image16 from '../assets/pocketFM/16.jpeg';
import image17 from '../assets/pocketFM/17.jpeg';
import image18 from '../assets/pocketFM/18.jpeg';
import image19 from '../assets/pocketFM/19.jpeg';
import image20 from '../assets/pocketFM/20.jpeg';
import image21 from '../assets/pocketFM/21.jpeg';
import image22 from '../assets/pocketFM/22.jpeg';
import image23 from '../assets/pocketFM/23.jpeg';
import image24 from '../assets/pocketFM/24.jpeg';
import image25 from '../assets/pocketFM/25.jpeg';
import image26 from '../assets/pocketFM/26.jpeg';
import image27 from '../assets/pocketFM/27.jpeg';
import image28 from '../assets/pocketFM/28.jpeg';
import image29 from '../assets/pocketFM/29.jpeg';
import image30 from '../assets/pocketFM/30.jpeg';
import image31 from '../assets/pocketFM/31.jpeg';
import image32 from '../assets/pocketFM/32.jpeg';
import image33 from '../assets/pocketFM/33.jpeg';

const projectImages = [
    image1, image2, image3, image4, image5, image6,
    image7, image8, image9, image10, image11, image12,
    image13, image14, image15, image16, image17, image18,
    image19, image20, image21, image22, image23, image24,
    image25, image26, image27, image28, image29, image30,
    image31, image32, image33
];

const Services3Comp = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false,
            delay: 200,
        });
    }, []);

    return (
        <div className="services-container">
            <div className="grid-container">
                {projectImages.map((image, index) => (
                    <Link to={``} key={index} className="grid-item" data-aos="fade-up" data-aos-delay={`${300 + index * 100}`}>
                        <img src={image} alt={`Project ${index + 1}`} />
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default Services3Comp;
