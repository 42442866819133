import React from 'react';
import './PortfolioDetails.css';
import { useParams } from 'react-router-dom';
import data from './projectData.json'; // Assuming data.js is in the same directory

const PortfolioDetails = () => {
  const { id } = useParams();
  const project = data.find(project => project.id === parseInt(id));

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div className="portfolio-details-container">
      <div className="portfolio-details-header">
        <div className="portfolio-details-title-container">
          <h2 className="portfolio-details-title">{project.title}</h2>
        </div>
        <div className="portfolio-details-video">
          <iframe
            width="560"
            height="315"
            src={project.videoUrl}
            title={project.title}
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <hr className="portfolio-details-divider" />
      <div className="portfolio-details-description">
        <h3>About</h3>
        <p>{project.description}</p>
      </div>
    </div>
  );
};

export default PortfolioDetails;
