import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Home7Comp.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import logo1 from '../../assets/clients/1-removebg-preview.png';
import logo2 from '../../assets/clients/2-removebg-preview.png';
import logo3 from '../../assets/clients/3-removebg-preview.png';
import logo4 from '../../assets/clients/4-removebg-preview.png';
import logo5 from '../../assets/clients/5-removebg-preview.png';
import logo6 from '../../assets/clients/6-removebg-preview.png';
import logo7 from '../../assets/clients/7-removebg-preview.png';
import logo8 from '../../assets/clients/8-removebg-preview.png';
import logo10 from '../../assets/clients/10-removebg-preview.png';

const Home7Comp = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const settings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: 'linear',
    infinite: true,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <div className="Client-container">
      <h2 data-aos="fade-up">Our Clients</h2>
      <Slider {...settings} className="customer-logos slider">
        <div className="slide">
          <img src={logo1} alt="Logo 1" />
        </div>
        <div className="slide">
          <img src={logo2} alt="Logo 2" />
        </div>
        <div className="slide">
          <img src={logo3} alt="Logo 3" />
        </div>
        <div className="slide">
          <img src={logo4} alt="Logo 4" />
        </div>
        <div className="slide">
          <img src={logo5} alt="Logo 5" />
        </div>
        <div className="slide">
          <img src={logo6} alt="Logo 6" />
        </div>
        <div className="slide">
          <img src={logo7} alt="Logo 7" />
        </div>
        <div className="slide">
          <img src={logo8} alt="Logo 8" />
        </div>
        <div className="slide">
          <img src={logo10} alt="Logo 10" />
        </div>
      </Slider>
    </div>
  );
};

export default Home7Comp;
