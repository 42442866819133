import React from 'react';
import './Home.css'
import Service1Comp from '../Components/Services/Service1Comp';
import ServiceHeroSection from '../Components/Services/ServiceHeroSection';
import Services3Comp from '../Components/Services/Services3Comp';
import ServiceIntro from '../Components/Services/ServiceIntro ';

function Services() {
  return (
    <div className="content">
    <ServiceHeroSection/>
    <ServiceIntro/>
    <Service1Comp/>
    <Services3Comp/>
    </div>
  );
}

export default Services;
