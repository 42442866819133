import React, { useState, useEffect } from 'react';
import './ContactUs1Comp.css'; // Import your CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import shapeImg from '../assets/lounge-man-working-on-web-design.gif'; // Replace with your image path
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs1Comp = () => {
  useEffect(() => {
    AOS.init({
      once: false, // Animation occurs only once
      duration: 1000, // Animation duration
      delay: 200, // Delay for each animation
    });
  }, []);

  const [inputValues, setInputValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [statusMessage, setStatusMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatusMessage('Sending...');
    try {
      const response = await fetch('https://swayambhu-backend.vercel.app/api/contactForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(inputValues),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success('Form submitted successfully. We will reach out to you soon!');
        setInputValues({ name: '', email: '', phone: '', message: '' });
      } else {
        toast.error(`Error: ${data.error}`);
      }
    } catch (error) {
      toast.error(`Error: ${error.toString()}`);
    } finally {
      setIsSubmitting(false);
      setStatusMessage('');
    }
  };

  return (
    <div className='contact1comp'>
      <div className="contactus-container" data-aos="fade-up">
        <span className="big-circle"></span>
        <img src={shapeImg} className="square" alt="" />
        <div className="form">
          <div className="contact-info">
            <h3 className="title">Ready to Work with Us?</h3>
            <p className="text">
              Have a question or need more information? Fill out our contact form, and we'll get back to you promptly.
            </p>

            <p className='text'>Visit us at our Pune office or  You can also reach us by phone or email.</p>

            <div className="info">
              <div className="information" data-aos="fade-up" data-aos-delay="200">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <p>D4-102, Nyati Equatorial Phase 2, Bavdhan, Pune</p>
              </div>
              <div className="information" data-aos="fade-up" data-aos-delay="400">
                <FontAwesomeIcon icon={faEnvelope} />
                <p>swayambhu@swayambhustrategy.com</p>
              </div>
              <div className="information" data-aos="fade-up" data-aos-delay="600">
                <FontAwesomeIcon icon={faPhone} />
                <p>Priyanka Daithankar (+91-9763978086)</p>
              </div>
            </div>

            <div className="social-media" data-aos="fade-up" data-aos-delay="800">
              <p>Connect with us :</p>
              <div className="social-icons">
                <a href="#">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="#">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="#">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="#">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>

          <div className="contact-form" data-aos="fade-up" data-aos-delay="1000">
            <span className="circle one"></span>
            <span className="circle two"></span>

            <form onSubmit={handleSubmit} autoComplete="off">
              <h3 className="title">Contact us</h3>
              {['name', 'email', 'phone', 'message'].map((field, index) => (
                <div className={`input-container ${inputValues[field] ? 'input-has-value' : ''}`} key={field}>
                  {field === 'message' ? (
                    <textarea
                      name={field}
                      className="input"
                      value={inputValues[field]}
                      onChange={handleInputChange}
                      data-aos="fade-up"
                      data-aos-delay={1200 + index * 200}
                    ></textarea>
                  ) : (
                    <input
                      type={field === 'email' ? 'email' : field === 'phone' ? 'tel' : 'text'}
                      name={field}
                      className="input"
                      value={inputValues[field]}
                      onChange={handleInputChange}
                      data-aos="fade-up"
                      data-aos-delay={1200 + index * 200}
                    />
                  )}
                  <label htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                  <span>{field.charAt(0).toUpperCase() + field.slice(1)}</span>
                </div>
              ))}
              <input type="submit" value={isSubmitting ? "Sending..." : "Send"} className="btn" disabled={isSubmitting} />
              {statusMessage && <p className="status-message"></p>}
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ContactUs1Comp;
